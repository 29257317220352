<div class="row p-0 text-center">
<!-- <div class="col-lg-6 p-3">
    <img src="../../assets/image/FGFB-July.png" width="250" height="auto">
</div>
<div class="col-lg-6 p-3">
    <img src="../../assets/image/FRC1.png" width="250" height="auto">
</div> -->
</div>
<div class="container p-0 mt-0">
    <div class="row p-0 text-center">
    <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">


    <div class="carousel-inner bg-image">
        <!-- <div class="carousel-item active">
            <img src="assets/image/bg.png" class="d-block w-100" alt="...">
        </div> -->
        <div class="carousel-item active">
            <img src="assets/image/11.png" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
            <img src="assets/image/12.png" class="d-block w-100" alt="...">
        </div>
        <div class="carousel-item">
            <img src="assets/image/13.png" class="d-block w-100" alt="...">
        </div>
    </div>
    </div>
    </div>
    <div class="row text-center p-0">
    <div class="col-md-3 p-5">
        <a routerLink="/course/grade11" routerLinkActive="active">
            <div>
                <svg class="bi bi-bookmark" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M8 12l5 3V3a2 2 0 00-2-2H5a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V3a1 1 0 00-1-1H5a1 1 0 00-1 1v10.234z" clip-rule="evenodd"/>
                  </svg>
            </div>
            <div class="mt-3">
                Tamil Nadu STD XI
            </div>
        </a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/grade12">
            <div>
                <svg class="bi bi-bookmarks" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M7 13l5 3V4a2 2 0 00-2-2H4a2 2 0 00-2 2v12l5-3zm-4 1.234l4-2.4 4 2.4V4a1 1 0 00-1-1H4a1 1 0 00-1 1v10.234z" clip-rule="evenodd"/>
                    <path d="M14 14l-1-.6V2a1 1 0 00-1-1H4.268A2 2 0 016 0h6a2 2 0 012 2v12z"/>
                  </svg>
            </div>
            <div class="mt-3">
                Tamil Nadu STD XII
            </div>
        </a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/cbse9">
            <div>
                <svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-diamond-half" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M9.05.435c-.58-.58-1.52-.58-2.1 0L.436 6.95c-.58.58-.58 1.519 0 2.098l6.516 6.516c.58.58 1.519.58 2.098 0l6.516-6.516c.58-.58.58-1.519 0-2.098L9.05.435zM8 .989c.127 0 .253.049.35.145l6.516 6.516a.495.495 0 0 1 0 .7L8.35 14.866a.493.493 0 0 1-.35.145V.989z"/>
                </svg>
            </div>
            <div class="mt-3">
                CBSE IX
            </div>
        </a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/cbse10">
            <div>
                <svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-diamond" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.482 1.482 0 0 1 0-2.098L6.95.435zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"/>
                </svg>
            </div>
            <div class="mt-3">
                CBSE X
            </div>
        </a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/icse9">
            <div>
                <svg class="bi bi-app" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M11 2H5a3 3 0 00-3 3v6a3 3 0 003 3h6a3 3 0 003-3V5a3 3 0 00-3-3zM5 1a4 4 0 00-4 4v6a4 4 0 004 4h6a4 4 0 004-4V5a4 4 0 00-4-4H5z" clip-rule="evenodd"/>
                  </svg>
            </div>
            <div class="mt-3">
                ICSE IX
            </div>
        </a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/icse10">
            <div>
                <svg class="bi bi-app-indicator" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M5.5 2A3.5 3.5 0 002 5.5v5A3.5 3.5 0 005.5 14h5a3.5 3.5 0 003.5-3.5V8a.5.5 0 011 0v2.5a4.5 4.5 0 01-4.5 4.5h-5A4.5 4.5 0 011 10.5v-5A4.5 4.5 0 015.5 1H8a.5.5 0 010 1H5.5z" clip-rule="evenodd"/>
                    <path d="M16 3a3 3 0 11-6 0 3 3 0 016 0z"/>
                  </svg>
            </div>
            <div class="mt-3">
                ICSE X
            </div>
        </a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/grammar">
            <div>
                <svg class="bi bi-check-all" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M12.354 3.646a.5.5 0 010 .708l-7 7a.5.5 0 01-.708 0l-3.5-3.5a.5.5 0 11.708-.708L5 10.293l6.646-6.647a.5.5 0 01.708 0z" clip-rule="evenodd"/>
                    <path d="M6.25 8.043l-.896-.897a.5.5 0 10-.708.708l.897.896.707-.707zm1 2.414l.896.897a.5.5 0 00.708 0l7-7a.5.5 0 00-.708-.708L8.5 10.293l-.543-.543-.707.707z"/>
                  </svg>
            </div>
            <div class="mt-3">
                Grammar
            </div>
        </a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/practice">
            <div>
                <svg class="bi bi-book-half" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M3.214 1.072C4.813.752 6.916.71 8.354 2.146A.5.5 0 018.5 2.5v11a.5.5 0 01-.854.354c-.843-.844-2.115-1.059-3.47-.92-1.344.14-2.66.617-3.452 1.013A.5.5 0 010 13.5v-11a.5.5 0 01.276-.447L.5 2.5l-.224-.447.002-.001.004-.002.013-.006a5.017 5.017 0 01.22-.103 12.958 12.958 0 012.7-.869zM1 2.82v9.908c.846-.343 1.944-.672 3.074-.788 1.143-.118 2.387-.023 3.426.56V2.718c-1.063-.929-2.631-.956-4.09-.664A11.958 11.958 0 001 2.82z" clip-rule="evenodd"/>
                    <path fill-rule="evenodd" d="M12.786 1.072C11.188.752 9.084.71 7.646 2.146A.5.5 0 007.5 2.5v11a.5.5 0 00.854.354c.843-.844 2.115-1.059 3.47-.92 1.344.14 2.66.617 3.452 1.013A.5.5 0 0016 13.5v-11a.5.5 0 00-.276-.447L15.5 2.5l.224-.447-.002-.001-.004-.002-.013-.006-.047-.023a12.582 12.582 0 00-.799-.34 12.96 12.96 0 00-2.073-.609z" clip-rule="evenodd"/>
                  </svg>
            </div>
            <div class="mt-3">
                Practice
            </div>
        </a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/vocab">
            <div>
                <svg class="bi bi-book" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M3.214 1.072C4.813.752 6.916.71 8.354 2.146A.5.5 0 018.5 2.5v11a.5.5 0 01-.854.354c-.843-.844-2.115-1.059-3.47-.92-1.344.14-2.66.617-3.452 1.013A.5.5 0 010 13.5v-11a.5.5 0 01.276-.447L.5 2.5l-.224-.447.002-.001.004-.002.013-.006a5.017 5.017 0 01.22-.103 12.958 12.958 0 012.7-.869zM1 2.82v9.908c.846-.343 1.944-.672 3.074-.788 1.143-.118 2.387-.023 3.426.56V2.718c-1.063-.929-2.631-.956-4.09-.664A11.958 11.958 0 001 2.82z" clip-rule="evenodd"/>
                    <path fill-rule="evenodd" d="M12.786 1.072C11.188.752 9.084.71 7.646 2.146A.5.5 0 007.5 2.5v11a.5.5 0 00.854.354c.843-.844 2.115-1.059 3.47-.92 1.344.14 2.66.617 3.452 1.013A.5.5 0 0016 13.5v-11a.5.5 0 00-.276-.447L15.5 2.5l.224-.447-.002-.001-.004-.002-.013-.006-.047-.023a12.582 12.582 0 00-.799-.34 12.96 12.96 0 00-2.073-.609zM15 2.82v9.908c-.846-.343-1.944-.672-3.074-.788-1.143-.118-2.387-.023-3.426.56V2.718c1.063-.929 2.631-.956 4.09-.664A11.956 11.956 0 0115 2.82z" clip-rule="evenodd"/>
                  </svg>
            </div>
            <div class="mt-3">
                Vocabulaire
            </div>
        </a>
    </div>
    <!-- <div class="col-md-3 p-5">
        <a routerLink="/videos">
            <div>
                <svg class="bi bi-book" width="3em" height="3em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M3.214 1.072C4.813.752 6.916.71 8.354 2.146A.5.5 0 018.5 2.5v11a.5.5 0 01-.854.354c-.843-.844-2.115-1.059-3.47-.92-1.344.14-2.66.617-3.452 1.013A.5.5 0 010 13.5v-11a.5.5 0 01.276-.447L.5 2.5l-.224-.447.002-.001.004-.002.013-.006a5.017 5.017 0 01.22-.103 12.958 12.958 0 012.7-.869zM1 2.82v9.908c.846-.343 1.944-.672 3.074-.788 1.143-.118 2.387-.023 3.426.56V2.718c-1.063-.929-2.631-.956-4.09-.664A11.958 11.958 0 001 2.82z" clip-rule="evenodd"/>
                    <path fill-rule="evenodd" d="M12.786 1.072C11.188.752 9.084.71 7.646 2.146A.5.5 0 007.5 2.5v11a.5.5 0 00.854.354c.843-.844 2.115-1.059 3.47-.92 1.344.14 2.66.617 3.452 1.013A.5.5 0 0016 13.5v-11a.5.5 0 00-.276-.447L15.5 2.5l.224-.447-.002-.001-.004-.002-.013-.006-.047-.023a12.582 12.582 0 00-.799-.34 12.96 12.96 0 00-2.073-.609zM15 2.82v9.908c-.846-.343-1.944-.672-3.074-.788-1.143-.118-2.387-.023-3.426.56V2.718c1.063-.929 2.631-.956 4.09-.664A11.956 11.956 0 0115 2.82z" clip-rule="evenodd"/>
                  </svg>
            </div>
            <div class="mt-3">
                Videos
            </div>
        </a>
    </div> -->
    <!-- <div class="col-md-3 p-5">
        <a routerLink="/course/grammar">Grammar</a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/practice">Practice</a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/france">France</a>
    </div>
    <div class="col-md-3 p-5">
        <a routerLink="/course/articles">Articles</a>
    </div> -->
</div>
</div>

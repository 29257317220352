<!-- <div class="container"> -->
  <div class="mt-5 mb-5">
    <div class="col-md-12">
      <nav class="navbar navbar-expand-lg fixed-top navbar-dark ofa-bg-blue">
        <a class="navbar-brand" href="">
          <img src="/assets/image/logo3.png" width="30" height="30" class="d-inline-block align-top" alt="">
          Obeth's French Academy</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href="">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Courses
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" routerLink="/course/grade11">STD XI</a>
                <a class="dropdown-item" routerLink="/course/grade12">STD XII</a>
                <a class="dropdown-item" routerLink="/course/grammar">Grammar</a>
                <a class="dropdown-item" routerLink="/course/practice">Practice</a>
                <a class="dropdown-item" routerLink="/course/icse9">ICSE STD IX</a>
                <a class="dropdown-item" routerLink="/course/icse10">ICSE STD XI</a>
                <a class="dropdown-item" routerLink="/course/vocab">Vocabulaire</a>
                <!-- <a class="dropdown-item" routerLink="/videos">Videos</a> -->
              </div>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="/about">About the Teacher <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="/contact">Contact<span class="sr-only">(current)</span></a>
            </li>
          </ul>
        </div>
      </nav>
    <!-- </div> -->
      <router-outlet></router-outlet>
    </div>
  </div>
<!-- </div> -->
<div class="text-center col-lg-12 mb-3">
  © 2020 Obeth's French Academy
</div>
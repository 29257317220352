<div class="container first-page">
    <div class="row">
        <h4 class="p-2 mt-3 ml-2 col-md-12">Contact</h4>
        <p class="p-2 mt-3 ml-2">Please feel free to contact us at the following email address for details regarding French classes only.</p>
        
        <div class="card col-md-12 p-0 mb-3">
            
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <div class="contact-info">
                    <svg class="bi bi-envelope" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M14 3H2a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V4a1 1 0 00-1-1zM2 2a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V4a2 2 0 00-2-2H2z" clip-rule="evenodd"/>
                        <path fill-rule="evenodd" d="M.071 4.243a.5.5 0 01.686-.172L8 8.417l7.243-4.346a.5.5 0 01.514.858L8 9.583.243 4.93a.5.5 0 01-.172-.686z" clip-rule="evenodd"/>
                        <path d="M6.752 8.932l.432-.252-.504-.864-.432.252.504.864zm-6 3.5l6-3.5-.504-.864-6 3.5.504.864zm8.496-3.5l-.432-.252.504-.864.432.252-.504.864zm6 3.5l-6-3.5.504-.864 6 3.5-.504.864z"/>
                    </svg>
                </div>
                <div class="contact-info">
                    contact@obethsfrenchacademy.com
                </div>
            </li>
            <li class="list-group-item">
                <div class="contact-info">
                    <svg class="bi bi-phone" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M11 1H5a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                        <path fill-rule="evenodd" d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                      </svg>
                </div>
                <div class="contact-info">
                    +91 98400 98270
                </div>
            </li>
        </ul>
        </div>
    </div>
    </div>
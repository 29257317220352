import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ServerComponent } from './server/server.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserComponent } from './user/user.component';
import { CourseComponent } from './course/course.component';
import { AboutComponent } from './about/about.component';
// import { VideoComponent } from './video/video.component';
import { OnlinetestComponent } from './onlinetest/onlinetest.component';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'server', component: ServerComponent },
  { path: 'user', component: UserComponent },
  // { path: 'videos', component: VideoComponent },
  { path: 'course/:category', component: CourseComponent },
  { path: 'onlinetest/:testid', component: OnlinetestComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="container">
    <div class="row">
        <h4 class="p-2 col-md-12 mt-3 ml-2 text-center">Online Test 1 - PRÉPOSITIONS</h4>
        <div class="card col-md-12 p-0 mb-3" *ngFor="let testitem of test_prepositions_1; let i=index">
        <div class="card-header font-weight-bold ofa-font-red">
            {{ i+1 }}. {{ testitem.q_desc }}
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <div>a. {{ testitem.q_a }}</div>
            </li>
            <li class="list-group-item">
                <div>b. {{ testitem.q_b }}</div>
            </li>
            <li class="list-group-item">
                <div>c. {{ testitem.q_c }}</div>
            </li>
            <li class="list-group-item">
                <div>d. {{ testitem.q_d }}</div>
            </li>
        </ul>
      </div>
    </div>
    </div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-onlinetest',
  templateUrl: './onlinetest.component.html',
  styleUrls: ['./onlinetest.component.css']
})
export class OnlinetestComponent implements OnInit {
  
  test_id: string = "";
  test_prepositions_1 = [
    {
      q_id: "1",
      q_desc: "Sabine joue au tennis _____ ses amis",
      q_a: "devant",
      q_b: "derrière",
      q_c: "sur",
      q_d: "avec",
      q_ans: "avec"
    },
    {
      q_id: "2",
      q_desc: "Est-ce que tu travailles _____ 5 heures du soir?",
      q_a: "dans",
      q_b: "en",
      q_c: "jusqu’à",
      q_d: "sous",
      q_ans: "jusqu’à"
    },
    {
      q_id: "3",
      q_desc: "Elle va _____ France",
      q_a: "à",
      q_b: "en",
      q_c: "par",
      q_d: "dans",
      q_ans: "en"
    },
    {
      q_id: "4",
      q_desc: "Les enfants courent _____ la maison",
      q_a: "autour de",
      q_b: "après",
      q_c: "avec",
      q_d: "pour",
      q_ans: "autour de"
    },
    {
      q_id: "5",
      q_desc: "Jean est le meilleur étudiant _____ ces garçons",
      q_a: "sous",
      q_b: "malgré",
      q_c: "entre",
      q_d: "parmi",
      q_ans: "parmi"
    }
  ]
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.test_id = params['testid'];
          // this.getTestID();
        }
      )
  }

}

<div class="container p-0">
<div class="row">
  <div class="col-md-12 mt-3 p-2"><h5>{{ courseName }}</h5></div>
  <div class="col-md-12 mb-3 p-0">
    
    <nav>
      <div class="nav nav-tabs ml-2" id="nav-tab" role="tablist">
        <a class="nav-item nav-link item-tab active" id="nav-doc-tab" data-toggle="tab" href="#nav-doc" role="tab" aria-controls="nav-doc" aria-selected="true">Documents</a>
        <a class="nav-item nav-link item-tab" id="nav-vdo-tab" data-toggle="tab" href="#nav-vdo" role="tab" aria-controls="nav-vdo" aria-selected="false" *ngIf="videoList">Videos</a>
      </div>
    </nav>
    <div class="tab-content" id="nav-tabContent">
      <div class="tab-pane fade show active" id="nav-doc" role="tabpanel" aria-labelledby="nav-doc-tab">
        <div *ngFor="let eachCourse of courseList; let i=index">
          <div class="card col-md-12 p-0" *ngFor="let courseLesson of eachCourse.lessons">
            <div class="card-header font-weight-bold ofa-font-red" *ngIf="courseLesson.group">
              {{ courseLesson.group }}
            </div>
            <ul class="list-group list-group-flush">
                <li *ngFor="let courseItem of courseLesson.items" class="list-group-item">
                    <a href="/assets/{{ courseItem.location }}">{{ courseItem.name }}</a>
                </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="nav-vdo" role="tabpanel" aria-labelledby="nav-vdo-tab">
        <div class="row m-0" >
          <div class="col-md-9 p-0">
              <div *ngIf="videoFileName">
              <div class="card-header font-weight-bold ofa-bg-blue">
                  {{videoGroup}} - {{videoTitle}}
              </div>
              <video class="oa-vdo-medium" [src]="videoFileName" controls autoplay="true">
                  
                  Your browser does not support the video tag.
              </video>
              </div>
              <div class="div-notify" *ngIf="videoFileName==''">
                Please click on a Lesson to watch the video
              </div>
          </div>
          <div class="col-md-3 p-0 listLesson">
          <div class="accordion" id="listLesson">
            <div *ngFor="let eachVideo of videoList; let i=index">
              <div class="card" *ngFor="let videoLesson of eachVideo.lessons; let i=index">
                <div class="card-header font-weight-bold ofa-font-red" id="headingOne" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                    
                      
                      {{ videoLesson.group }}
                  
                </div>
            
                <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                  
                  <div class="card-body" *ngFor="let videoItem of videoLesson.items">
                      <div (click)="showVideo(videoLesson.group, videoItem)">{{ videoItem.title }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          
      </div>
      </div>
    </div>



  </div>
    
</div>
</div>
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UserComponent } from './user/user.component';
import { ServerComponent } from './server/server.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CourseComponent } from './course/course.component';
import { AboutComponent } from './about/about.component';
import { VideoComponent } from './video/video.component';
import { OnlinetestComponent } from './onlinetest/onlinetest.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    ServerComponent,
    DashboardComponent,
    CourseComponent,
    AboutComponent,
    VideoComponent,
    OnlinetestComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="container">
<div class="row">
    <h4 class="p-2 mt-3 ml-2">About the Teacher</h4>
    <div class="card col-md-12 p-0 mb-3">
    <ul class="list-group list-group-flush">
        <li class="list-group-item">
            <strong>J. OBETH JEBAKUMAR</strong> is a renowned French Teacher in Chennai, India.<br>
            He has done his Master's in FRENCH LITERATURE at the University of Madras.<br>
            Obeth furthered his French education at Alliance Française de Madras and his German education at Goethe Institut.<br><br>
            Over the years, since 1998, Obeth has taught French in several prestigious educational institutions including the following,<br>
            - Loyola College, <i>Chennai, India</i><br>
            - Ethiraj College, <i>Chennai, India</i><br>
            - Doveton Corrie Schools, <i>Chennai, India</i><br>
            - SBOA School, <i>Chennai, India</i><br>
            - Ewart School, <i>Chennai, India</i><br>
            - Bain School, <i>Chennai, India</i><br>
            - and many more<br><br>
            
            Obeth has traveled to many countries including Switzerland, France, Germany, UK and USA<br>
            While in Switzerland he had specialized training in French Literature in Geneva.<br><br>
            
            Currently, Obeth continues to teach at Doveton Corrie Schools and CSI Ewart School.
        </li>
    </ul>
    </div>
</div>
</div>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import Doc from '../../assets/data/doc.json';
import Vdo from '../../assets/data/vdo.json';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.css']
})
export class CourseComponent implements OnInit {
category: string;

courseList: any = [];
videoList: any = [];

courseName = "";
videoFileName = "";
videoGroup = "";
videoTitle = "";

docs = Doc;
vdos = Vdo;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params
      .subscribe(
        (params: Params) => {
          this.category = params['category'];
          this.getCategory();
        }
      )
  }

  getCategory() {
    console.log("The selected category is " + this.category);
    this.courseList = this.docs[this.category];
    this.courseName = this.courseList[0].classname;
    console.log("Course Name is " + this.courseList[0].classname);
    this.videoList = this.vdos[this.category];
    console.log("Documents are " + JSON.stringify(this.courseList));
    console.log("Videos are " + JSON.stringify(this.videoList));
  }

  showVideo(group, video) {
    this.videoFileName = "/assets/videos/" + video.filename;
    this.videoTitle = video.title;
    this.videoGroup = group;
  }

}